<template>
  <div class="header flex-r">
    <div class="header_logo flex-r">
       <img src ="../../../../assets/images/login_logo.png"/>
      <span class="fz-14">{{ $store.state.company}}</span>
    </div>
    <!--<div class="header_navbar flex-r flex-1" v-if="navbarList.length > 0">
      <a v-for="(item, index) in navbarList" :class="[navbarActiveName === item.name ? 'active' : '']" :key="index"
        @click="selecNavbar(item)">{{ item.title }}</a>
    </div>--> 
    <div class="header_account flex-r">
      <el-dropdown>
        <div class="user flex-r">
          <span class="name">{{this.username}}</span>
          <img :src="this.photo" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!--<el-dropdown-item @click="jumpPage('/change-pwd')">修改密码</el-dropdown-item>-->
          <el-dropdown-item @click.native="logOut">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Header',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        navBarActive: '',
        showPopover: false,
        userInfo: '',
        username:'',
        photo:''
      }
    },
    //计算属性
    computed: {
      ...mapGetters(['navbarList', 'navbarActiveName'])
    },
    watch: {},
    created() { },
    beforeMount() { },
    mounted() {
      let route = this.$route
      this.$store.commit(
          'SET_NAVBAR_ACTIVE_NAME',
           route.matched[0].meta.belongNavbar
      )
        this.username=window.sessionStorage['username']
        this.photo=window.sessionStorage['photo']
    },
    //方法
    methods: {
      selecNavbar(navbarItem) {
        this.$store.commit('SET_NAVBAR_ACTIVE_NAME', navbarItem.name)
        this.jumpPage(navbarItem.path)
      },
      jumpPage(url) {
        this.$router.push({ path: url, query: {} })
      },
      logOut() {
        this.$router.push('/login')
        this.$store.dispatch('LogOut')
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '../../../../assets/scss/colors';
  .header {
    background-color: $cl-main;
    color: $cl-white;
    height: 60px;
    line-height: 60px;
    .header_logo {
      padding: 0 20px;
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        margin-right: 20px;
      }
    }
    .header_navbar {
      align-items: center;
      font-size: 14px;
      padding: 0 0px;
      a {
        color: $cl-white;
        padding: 0 0px;
        height: 60px;
        line-height: 60px;
      }
      a.active,
      a:hover {
        background-color: $cl-blue;
      }
    }
    .header_account {
      justify-content: flex-end;
      align-items: center;
      padding: 0 0px;
      cursor: pointer;
      position: absolute;
      right: 20px;
      .user {
        height: 60px;
        align-items: center;
        .name {
          color: $cl-white;
          margin-right: 6px;
        }
        img {
          width: 32px;
          height: 32px;
          background: #fff;
          border-radius: 50%;
        }
      }
    }
  }
</style>